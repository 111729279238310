import React, { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import styles from "../main/Main.module.css";
import closeIcon from "../../assets/closeIcon.png";
import hamburguerIcon from "../../assets/hamburguerIcon.png";
import logo_alsina from "../../assets/logo_alsina.png";
import arrowUp from "../../assets/arrowUp.png";
import arrowDown from "../../assets/arrowDown.png";
import no_verificado from "../../assets/no_verificado.png";
import Pedido from "../../components/Pedido";
import TestSQL from "../../components/TestSQL";
///3405 es para 1001 /////3408 para 1002 //////3411 para 1002/// 4662 para el 1420 EL QUE CONTIENE TODO///////////////
//La version con el nuevo encriptado para el 1420 es U2FsdGVkX1  8L7yifFRhDxGlqNGM= ////////////

//para testear el backv2 /YEpApH+KjA7NZaBlVDw==
const Main = () => {
  const [claveSecreta, setClaveSecreta] = useState("clavePrivada");
  const [navMailToggle, setNavMailToggle] = useState(false);
  const [successSave, setSuccessSave] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [valorDesencriptadoU, setvalorDesencriptadoU] = useState("");
  const [nombreObra, setNombreObra] = useState("");
  const [numeroAlquiler, setNumeroAlquiler] = useState("");
  const [propsArray, setPropsArray] = useState([]);

  useEffect(() => {
    function detectarCambioPantalla() {
      const anchoVentana = window.innerWidth;
      const notAcceptedDeviceElement = document.getElementsByClassName(
        styles.notAcceptedDevice
      )[0];

      if (anchoVentana >= 895) {
        setTimeout(() => {
          console.log("Muy grande");
          notAcceptedDeviceElement.style.display = "flex";
        }, 100);
      } else {
        notAcceptedDeviceElement.style.display = "none";
      }
    }

    window.addEventListener("resize", detectarCambioPantalla);
    detectarCambioPantalla();
    //Obtener numero de referencia del local storage, desecriptarlo, y llamar a la bdd.
    desencryptReference();

    return () => {
      window.removeEventListener("resize", detectarCambioPantalla);
    };
  }, []);
  /////////////////////////////////PREVENT QUERY LEAK IN LOCALSTORAGE//////////////////////////////////////
  useEffect(() => {
    // console.log("localstorage refreshed");
    const keepKeys = ["expiration", "referenceCode"];
    // Crear un objeto temporal para guardar los valores de las variables que queremos conservar
    let keepValues = {};
    // Recorrer todas las claves en localStorage
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      // Si la clave es una de las que queremos conservar, la guardamos
      if (keepKeys.includes(key)) {
        keepValues[key] = localStorage.getItem(key);
      }
    }
    // Limpiar todo el localStorage
    localStorage.clear();
    // Restaurar las variables que queremos conservar
    for (let key in keepValues) {
      localStorage.setItem(key, keepValues[key]);
    }
    // console.log("Se han eliminado todas las claves excepto:", keepKeys);
  }, [localStorage.length]);
  /////////////////////////////////PREVENT QUERY LEAK IN LOCALSTORAGE//////////////////////////////////////

  var toggleMenu = true;

  function displayNav() {
    var img = document.getElementById("menuTrigger");
    var cerrar = closeIcon;
    var abrir = hamburguerIcon;

    if (toggleMenu) {
      img.src = cerrar;
      toggleMenu = false;
      document.getElementById("dropDownMenu").style.display = "block";
    } else {
      img.src = abrir;
      toggleMenu = true;
      document.getElementById("dropDownMenu").style.display = "none";
    }
  }
  function redirect() {
    var currentURL = window.location.href;
    var newURL = currentURL.replace(/\/[^\/]*$/, "/");
    window.location.href = newURL;
  }

  //funcior para activar o desactivar el menu para linkar un mail
  function toggleEmailNotification() {
    if (navMailToggle) {
      document.getElementById("secondRowMail").style.display = "flex";
      document.getElementById("secondRowMailV2").style.display = "none";
    } else {
      document.getElementById("secondRowMail").style.display = "none";
      document.getElementById("secondRowMailV2").style.display = "flex";
    }
    setNavMailToggle(!navMailToggle);
  }

  //Mostrar o ocultar menu de guardado exitoso
  function displaySuccessSave() {
    //comprobar si es un formato de mail correcto
    if (emailInput.length > 4 && emailInput.indexOf("@") > -1) {
      if (successSave) {
        document.getElementById("secondRowMailV2").style.display = "none";
        document.getElementById("firstRowMail").style.display = "none";
        document.getElementById("succesSavePopUp").style.display = "flex";
        saveMail();
      } else {
        document.getElementById("secondRowMailV2").style.display = "flex";
        document.getElementById("firstRowMail").style.display = "flex";
        document.getElementById("succesSavePopUp").style.display = "none";
      }
      setSuccessSave(!successSave);
    } else {
      alert("Introduce una direccion de correo valida");
    }
  }
  //////////////////////////////////Guardar Correo Electronico///////////////////////////////////////////
  function saveMail() {
    fetch(
      `https://www.alsinatracking.com:3001/actualizar-correo/${valorDesencriptadoU}/${emailInput}`,
      {
        method: "PUT",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Mensaje de éxito o error
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    console.log("Valor del input:", emailInput);
  }
  ///////////////////////////////////////////Eliminar correo vinculado//////////////////////////////////////////
  function unlinkMail() {
    var checkboxStatus = document.getElementById("emailToggle").checked;
    // console.log("checkboxStatus: " + checkboxStatus);
    if (checkboxStatus === false) {
      fetch(
        `https://www.alsinatracking.com:3001/desvincular-correo/${valorDesencriptadoU}`,
        {
          method: "PUT",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data); // Mensaje de éxito o error
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }
  //////////////////////////////////Desencriptar codigo de Referencia///////////////////////////////////////////
  function desencryptReference() {
    checkTokenExpired();
    var startIngPart = "U2FsdGVkX1";
    let toDecript = localStorage.getItem("referenceCode");
    var newValue = startIngPart + toDecript.toString();
    // toDecript = +startIngPart;
    // console.log(toDecript);
    // console.log(newValue);
    try {
      var valorDesencriptado = CryptoJS.RC4Drop.decrypt(
        newValue.toString(),
        claveSecreta
      ).toString(CryptoJS.enc.Utf8);
      //console.log("Valor desencriptado: " + valorDesencriptado);
      setvalorDesencriptadoU(valorDesencriptado);
      ejecutarConsultaSQL(valorDesencriptado);
    } catch (error) {
      alert("codigo invalido");
      redirect();
    }
  }

  function checkTokenExpired() {
    let expirationDate = localStorage.getItem("expiration");
    var expirationMonth = expirationDate.split("-");

    // console.log(expirationMonth[1]);
    let currentDateToCheck = new Date();
    let currentDateToCheckString = currentDateToCheck
      .toISOString()
      .split("T")[0];
    let currentMonthToCheck = currentDateToCheckString.split("-");
    if (currentMonthToCheck[1] == !expirationMonth[1]) {
      alert("Sesion Expirada");
      redirect();
    }
  }
  /////////////////Consulta SQL ESCUCHANDO EN LOCALHOST PUERTO 3001 PARA OBTENER TODA LA INFORMACION DE LA BDD////////////////
  const secretKey = process.env.REACT_APP_PRIVATE_KEY;

  const encriptarValor = (valor) => {
    const ivwa = CryptoJS.enc.Utf8.parse("1234567890123456"); // IV de 16 bytes
    const encriptado = CryptoJS.AES.encrypt(
      valor,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: ivwa,
      }
    );

    // Convertir a hexadecimal
    const hex = encriptado.ciphertext.toString(CryptoJS.enc.Hex);
    return encodeURIComponent(hex); // Codifica el valor
  };
  ////////////////////////AQUI ACABA EL ENCRIPTADO/////////////////////////
  const ejecutarConsultaSQL = async (valorParaQuery) => {
    var valorParaQuery = encriptarValor(valorParaQuery);
    try {
      const response = await axios.get(
        `https://www.alsinatracking.com:3001/consultar-pedidos/${valorParaQuery}`
      );
      let queryResponse = response.data;
      postProcesado(queryResponse);
    } catch (error) {
      console.error("Error al ejecutar la consulta en el frontEnd:", error);
    }
  };

  //////////////////////////////////METODO ENCARGADO DEL POST PROCESADO DE LA INFO///////////////////////////////////////
  const postProcesado = (valores) => {
    //console.log("Diccionario original");
    //console.log(valores);
    //console.log("como string");
    //console.log(JSON.stringify(valores));
    //gestionar si es erroneo
    if (valores.length == 0) {
      console.log("vacio");
      redirect();
    }
    //Eliminar informacion irrelevante
    const fechaReducida = valores.map(
      ({ fecha_realizacion_pedido, fecha_prevista_pedido, ...resto }) => ({
        ...resto,
        fecha_realizacion_pedido: fecha_realizacion_pedido.split("T")[0],
        fecha_prevista_pedido: fecha_prevista_pedido
          ? fecha_prevista_pedido.split("T")[0]
          : null,
      })
    );
    //console.log("Fecha sin la morralla");
    //console.log(fechaReducida);

    const fechaReducidaOrdenada = fechaReducida
      .map(({ fecha_realizacion_pedido, fecha_prevista_pedido, ...resto }) => ({
        ...resto,
        fecha_realizacion_pedido: fecha_realizacion_pedido
          ? formatDate(fecha_realizacion_pedido)
          : null,
        fecha_prevista_pedido: fecha_prevista_pedido
          ? formatDate(fecha_prevista_pedido)
          : null,
      }))
      .sort((a, b) => {
        return (
          new Date(a.fecha_realizacion_pedido) -
          new Date(b.fecha_realizacion_pedido)
        );
      });
    //console.log("Fecha bien formateada: ");
    //console.log(fechaReducidaOrdenada);

    //Agrupar por pedidos//
    const datosAgrupados = fechaReducidaOrdenada.reduce(
      (acumulador, elemento) => {
        const numeroPedido = elemento.numero_pedido;
        if (!acumulador[numeroPedido]) {
          acumulador[numeroPedido] = [];
        }
        acumulador[numeroPedido].push(elemento);
        return acumulador;
      },
      {}
    );
    //console.log("Agrupados por numero de pedido");
    //console.log(datosAgrupados);

    ///////////////////////////Unificar materiales pedidos/////////////////////////////////////
    const combinado = Object.values(datosAgrupados).map((array) => {
      return array.reduce((acumulador, objeto) => {
        const {
          codigo_producto,
          cantidad_producto,
          descripcion_producto,
          ...resto
        } = objeto;
        if (!acumulador) {
          return {
            ...resto,
            listado: [
              { codigo_producto, cantidad_producto, descripcion_producto },
            ],
          };
        }
        acumulador.listado.push({
          codigo_producto,
          cantidad_producto,
          descripcion_producto,
        });
        return acumulador;
      }, null);
    });
    //console.log("Resultado  casi final");
    //console.log(combinado);
    ///////ORDENAMOS PEDIDOS DE MAYOR A MENOR (YA QUE SIGUEN UN ORDEN NUMERICO Y, POR ENDE, LOS MAS GRANDES SON LOS MAS RECIENTES)//////////////

    combinado.sort((a, b) => {
      const orderA = a.numero_pedido;

      const orderB = b.numero_pedido;
      return orderB - orderA;
    });

    ////////////////////////Alternativa, ordenar por fecha///////////////////////////

    // combinado.sort((a, b) => {
    //   const orderA = a.fecha_realizacion_pedido;

    //   const orderB = b.fecha_realizacion_pedido;
    //   return orderB - orderA;
    // });

    //console.log("Resultado final como string");
    //console.log(JSON.stringify(combinado));

    //////////SETEAR EL NOMBRE DE LA OBRA, NUMERO DE REFERENCIA Y PREPARAR ARRAY PARA PASAR LOS PROPS//////////////////////
    setNombreObra(combinado[0].nombre_obra_alquiler);
    setNumeroAlquiler(combinado[0].ficha_alquiler);
    setPropsArray(combinado);
    //console.log(propsArray[0].ultima_latitud_transportista);
    //console.log(propsArray[0].ultima_longitud_transportista);

    /////////////////////////////GESTIONAR EL DESPLEGABLE DEL CORREO EN CASO DE QUE SEA O NO NULL///////////////////////////////////////////

    //////////////////////////////LOGS PARA TESTEAR//////////////////////////////////
    // console.log("esto esta fino señores");
    // console.log(combinado[0].correo_asociado_alquiler);
    //////////////////////////////LOGS PARA TESTEAR//////////////////////////////////

    if (
      combinado[0].correo_asociado_alquiler != null &&
      combinado[0].correo_asociado_alquiler != ""
    ) {
      document.getElementById("secondRowMail").style.display = "none";
      document.getElementById("secondRowMailV2").style.display = "flex";
      document.getElementById("emailToggle").checked = true;
      setNavMailToggle(!navMailToggle);
      setEmailInput(combinado[0].correo_asociado_alquiler);
    }
  };
  //////////////////////////PONER EL FORMATO CORRECTO A LA FECHA///////////////////////////////////////
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  ///////////////////////////////Acabar de ajustar fechas y horas//////////////////////////////////////////////////////
  const convertirHora = (hora) => {
    // Dividimos la hora en partes separadas por :
    const partes = hora.split(":");
    // Retornamos las partes de hora y minutos
    return partes[0] + ":" + partes[1];
  };
  const convertirFecha = (fecha) => {
    // Dividimos la fecha en partes separadas por -
    const partes = fecha.split("-");
    // Reorganizamos las partes en el nuevo formato DD/MM/AAAA
    return partes[0] + "/" + partes[1] + "/" + partes[2];
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////Añadir a la pantalla de inicio//////////////////////////////////////////////////
  function AppLikeMovile() {}
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      {" "}
      {/* <TestSQL /> */}
      <div className={styles.notAcceptedDevice}>
        <div className={styles.notAcceptedDevicePopUP}>
          <p>
            <img className={styles.imageLogoPopUp} src={logo_alsina} />
          </p>
          <p>Esta aplicación solo es compatible con móvil o tablet</p>
        </div>
      </div>
      <nav className={styles.navBar}>
        <img src={logo_alsina} className={styles.logoNav} id="navBar" />
        <img
          id="menuTrigger"
          src={hamburguerIcon}
          className={styles.menuIcon}
          onClick={() => displayNav()}
        />
      </nav>
      <div className={styles.dropDownMenu} id="dropDownMenu">
        <div className={`${styles.generalContainerDropdown} ${styles.ctr}`}>
          <div className={styles.emailContainer}>
            <div id="firstRowMail" className={styles.firstRowMail}>
              <span>Vincular Correo electrónico</span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  id="emailToggle"
                  onClick={() => {
                    toggleEmailNotification();
                    unlinkMail();
                  }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div
              id="secondRowMail"
              className={`${styles.secondRowMail} ${styles.ctr}`}
            >
              <span className={styles.noteText}>
                Al activar esta opción, recibirás mails según se modifiquen los
                estados de los pedidos en esta ficha
              </span>
            </div>
            <div id="secondRowMailV2" className={styles.secondRowMailV2}>
              <input
                type="text"
                placeholder="correo@ejemplo.com"
                id="emailInput"
                onChange={(e) => setEmailInput(e.target.value)}
                value={emailInput}
              />
              <button onClick={() => displaySuccessSave()}>Guardar</button>
            </div>
            <div id="succesSavePopUp" className={styles.successSave}>
              <span id="textSuccess">Correo vinculado exitosamente</span>
              <button onClick={() => displaySuccessSave()}>Aceptar</button>
            </div>
          </div>
          <div className={styles.bottomPart}>
            <button className={styles.searchButton} onClick={() => redirect()}>
              Nueva búsqueda
            </button>
            {/* <button
              className={styles.searchButton}
              onClick={() => AppLikeMovile()}
            >
              Añadir a Inicio
            </button> */}
          </div>
        </div>
      </div>
      <div className={styles.mainContentContainer}>
        <div className={styles.buildTitle}>
          <span id="buildTitleSpan"> {nombreObra} </span>
        </div>
        {/* <div className={styles.rentSection}>
          <span className={styles.rentP1}> Número de Alquiler </span>
          <span className={styles.rentP2} id="RentNumber">
            {" "}
            {numeroAlquiler}{" "}
          </span>
        </div> */}

        <div className={styles.deliveryContainer}>
          <div className={`${styles.delviverySectionTitle} ${styles.ctr}`}>
            <span>Mis pedidos</span>
          </div>
          <div className={`${styles.delviveryContent} ${styles.ctr}`}>
            {/* Apartado Pedidos TODO: PASARLE PROPS A LOS PEDIDOS. PROPS NECESARIOS: LOS DE LA BDD */}
            {propsArray.map((pedidoIndependiente) => (
              <Pedido
                key={Math.random() * 99999}
                numeroPedido={pedidoIndependiente.numero_pedido}
                fechaPedido={convertirFecha(
                  pedidoIndependiente.fecha_realizacion_pedido
                )}
                delivery={pedidoIndependiente.tipo_transportista_pedido}
                status={pedidoIndependiente.estado_pedido}
                latitud_transportista={
                  pedidoIndependiente.ultima_latitud_transportista
                }
                longitud_transportista={
                  pedidoIndependiente.ultima_longitud_transportista
                }
                paradas={pedidoIndependiente.orden_entrega}
                fechaPlanificada={
                  convertirFecha(pedidoIndependiente.fecha_prevista_pedido)

                  // ? pedidoIndependiente.fecha_prevista_pedido
                  // : "Pendiente"
                }
                datosListaMateriales={pedidoIndependiente.listado}
                horaPlanificada={convertirHora(
                  pedidoIndependiente.hora_prevista_pedido
                )}
              />
            ))}

            {/* <Pedido
              numeroPedido="33215"
              fechaPedido="12/01/2024"
              delivery="propio"
              status="1"
              fechaPlanificada="03/12/2024"
              horaPlanificada="10:00"
              datosListaMateriales={datosDePrueba1}
            />
            <Pedido
              numeroPedido="88379"
              fechaPedido="24/02/2022"
              delivery="externo"
              status="2"
              fechaPlanificada="03/12/2024"
              horaPlanificada=""
              datosListaMateriales={datosDePrueba2}
            />
            <Pedido
              numeroPedido="79821"
              fechaPedido="12/01/2022"
              delivery="externo"
              status="3"
              fechaPlanificada="03/12/2024"
              horaPlanificada=""
              datosListaMateriales={datosDePrueba3}
            />
            <Pedido
              numeroPedido="09173"
              fechaPedido="19/12/2021"
              delivery="propio"
              status="4"
              fechaPlanificada="03/12/2024"
              horaPlanificada="10:00"
              datosListaMateriales={datosDePrueba4}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
